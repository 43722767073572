export { configClass };

import store from "../store";
import firebase from "firebase/app";
import "firebase/messaging";
import "firebase/analytics";
import { EventBus } from "../main";
import configVendor from "./vendor-config";

class configClass {
  constructor() {
    this._config = null;
    this._vendorId = null;
    this._vendorKey = null;
    this._theme = null;
    this._vendorData=null;
    this.loadConfig();
  }
  get config() {
    return this._config;
  }
  get vendorId() {
    return this._vendorId;
  }
  get vendorKey() {
    return this._vendorKey;
  }
  get theme() {
    return this._theme
  }
  get vendorData(){
    return this._vendorData;
  }

  loadConfig() {
    // fetch("https://vlite-preprod.firebaseio.com/config/web/v1.json?print=pretty", {
    // fetch("https://vlite-config.firebaseio.com/preprod/web/vlite/v1.json?print=pretty", { //vlite
    // fetch("https://vlite-config.firebaseio.com/prod/web/caveindia/v1.json", { //cave
    // fetch("https://vlite-config.firebaseio.com/prod/web/rokkt/v1.json", { //rokkt-tv
    const base_env =window.base_env;
    import(`@/base-configurations/vendors/${base_env}.js`).then((res)=>{
      this._vendorData=res;
      return (async ()=>{
          let config_data=null;
          for(let path of this.vendorData.config_path){
            try{
              let response= await  fetch(path, {method: "GET"});
              config_data=await response.json();
              if(config_data)break;
            }
            catch(err){
              console.log(err);
              continue;
            }
          }
          return config_data;
        })();
    })
      .then(data => {
        // data.vendorDetails.vendorKey=data.vendorDetails.vendorKey?data.vendorDetails.vendorKey:"8C0jCLwCdqTckuTB";
        this._config = data = data??JSON.parse(localStorage.getItem("appConfig"));
        // this._config.featureEnabled.subscription = false;
        // this._config.featureEnabled.purchase = true;
        this._vendorId = data.vendorDetails.vendorId;
        this._vendorKey = data.vendorDetails.vendorKey;
        this._theme = data.vendorDetails.defaultTheme;

        configVendor(this.vendorData,this._config.vendorDetails);
        
        this.initPlayer();
        this.initFBpixel();
        this.initAppTheme();
        store.dispatch("actLocate")
          .then(response => {
            if (response.success) {
              let country = response.success;
              localStorage.setItem("country", country);
              store.commit("commitCountry", country);
            }
          })
          .catch(error => {
            EventBus.$emit("signupError", error);
          })
          .then(()=>{
            store.dispatch('actCDMSupport');
          });
        this.initAppCycle();
        this.fetchVendorInfo();
        import(/* webpackChunkName: "userClass" */ /* webpackMode: "lazy" */ "./user-class").then(user => {
          this.user = new user.userClass();
        });
      })
      .catch(error => {
        console.log("[error]", error);
      });

  }
  initPlayer(){
    const vendorData=this.config.vendorDetails?? {};
    store.commit('commitPlayerSettings',{
      widevineLicenseURL: `${ vendorData.drmUrl }${ vendorData.vendorId }`
    });
    store.commit('commitPlayerInstance');
  }
  initAppCycle() {
    this.initAppFirebase();
    if(this.vendorData._gtmId){
      this.initGTM(window,document,'script','dataLayer',this.vendorData._gtmId);
    }
    this.initFacebookSDK();
  }
  initAppTheme() {
    localStorage.setItem("appConfig", JSON.stringify(this._config));
  }
  initGTM(w,d,s,l,i){
    w[l]=w[l]||[];w[l].push({'gtm.start':
    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
     
  }
  initAppFirebase() {
    // Check how many instance of firebase app has been registered. If already registered then apps.length will return >0. Then no need to register again.
    if (firebase.apps.length) return;

    // primary Firebase : Database linking

    const primaryAppConfig = this.vendorData.firebase.primary ;
    firebase.initializeApp( primaryAppConfig );

    // secondary Firebase - Only For FCM and analytics

    const secondaryAppConfig = this.vendorData.firebase.secondary ;
    firebase.initializeApp(secondaryAppConfig, "secondary");

    const messagingData = {
      serverKey: this.vendorData.firebase.serverKey,
      certificateKey: this.vendorData.firebase.certificateKey
    }

    store.commit( 'CommitFirebaseConfig', messagingData );
    store.commit("CommitUserStore");

    EventBus.$emit("configLoaded");
  }

  // appId: "550400871992321", 
  // 550400871992321
  // 307494103945879
  // 550400871992321//vlite
  //1382721665437071 cave
  initFacebookSDK() {
    let fbid=this.vendorData._fbId;
    window.fbAsyncInit = function () {
      FB.init({
        appId: fbid,
        cookie: true,
        xfbml: true,
        version: "v6.0"
      });
    };
    (function (d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) return;
      js = d.createElement(s);
      js.id = id;
      js.async = true;
      js.src = "https://connect.facebook.net/en_US/sdk.js";
      fjs.parentNode.insertBefore(js, fjs);
    })(document, "script", "facebook-jssdk");
  }


  fetchVendorInfo() {
    let appVendor = {};
    if (this._config.vendorDetails) {
      appVendor.vendorId = this._config.vendorDetails.vendorId;
      appVendor.vendorKey = this._config.vendorDetails.vendorKey;
      appVendor.vendorName = this._config.vendorDetails.vendorName;
    }
    localStorage.setItem("appVendor", JSON.stringify(appVendor));
    store.commit("CommitVendorStore");
    // Initializing vLive Analytics SDK
    this.initvLiveWebSdk();
  }

  initFBpixel() {
    if (!this.vendorData._pixelId) return;
    !function (f, b, e, v, n, t, s) {
      if (f.fbq) return;
      n = f.fbq = function () {
        n.callMethod ?
        n.callMethod.apply(n, arguments) : n.queue.push(arguments)
      };
      if (!f._fbq) f._fbq = n; n.push = n; n.loaded = !0; n.version = '2.0';
      n.queue = []; t = b.createElement(e); t.async = !0;
      t.src = v; s = b.getElementsByTagName(e)[0];
      s.parentNode.insertBefore(t, s)
    }(
      window,
      document,
      'script',
      'https://connect.facebook.net/en_US/fbevents.js');
    fbq('init', `${this.vendorData._pixelId}`);
    fbq('track', 'PageView');

  }

  initvLiveWebSdk() {
    if (!(this._vendorId && this._vendorKey)) return;

    window._activateSdk = () => {
      return new Promise((res) => {
        if (window.vLiveWebSdk) {
          return res(window.vLiveWebSdk);
        }
        import(
          /* webpackChunkName: 'vLiveWebSdk' */ "vLiveWebSdk/vLiveWebSdkBase"
        ).then(({default:VLiveBaseSdk}) => {
          let vLiveWebSdk = new VLiveBaseSdk(this._vendorId, this._vendorKey, this._config.vendorDetails.baseUrl);

          // Set the analytics SDK Object to global window object
          window.vLiveWebSdk = vLiveWebSdk;

          res(vLiveWebSdk);
        });
      })
    }


  }


}
